import React from "react";
import Amplify from "aws-amplify";
import List from "./views/List";
import Moderation from "./views/Moderation";
import { BrowserRouter, Route } from "react-router-dom";
import { API, graphqlOperation } from "aws-amplify";
import { createQuestion } from "./graphql/mutations";
import { formatISO } from "date-fns";
import Login from "./Login";
import awsconfig from "./aws-exports";
import "./App.css";

Amplify.configure(awsconfig);

const Header = () => {
  const [user, setUser] = React.useState("");

  const [content, setContent] = React.useState("");

  React.useEffect(() => {
    Amplify.Auth.currentUserInfo()
      .then((data) => {
        console.log(data);
        setUser(data.attributes.email);
      })
      .catch((err) => console.log(err));
  }, []);

  const submit = () => {
    API.graphql(
      graphqlOperation(createQuestion, {
        input: {
          content,
          username: user,
          date: formatISO(Date.now()),
        },
      })
    )
      .then((e) => {
        alert("Question added!");
        setContent("");
      })
      .catch((e) => console.log(e));
  };

  return (
    <>
      <div className="nes-container with-title ">
        {" "}
        <div className="nes-dialog">
          <form method="dialog">
            <p>
              To ask a question you can use a form below or `/pytanko` slack
              command
            </p>
            <div className="nes-field">
              <input
                type="text"
                id="name_field"
                className="nes-input"
                value={content}
                onChange={(e) => setContent(e.target.value)}
              />
            </div>
            <menu>
              <button
                className="nes-btn is-primary"
                onClick={() => submit()}
                style={{ float: "right" }}
              >
                Ask
              </button>
              <button
                className="nes-btn"
                onClick={() => setContent("")}
                style={{ float: "right", marginRight: "1rem" }}
              >
                Cancel
              </button>
              <div style={{ clear: "both" }} />
            </menu>
          </form>
        </div>
      </div>
      <div className="nes-container with-title ">
        <p className="title">Nordcloud asks</p>
        Hi {user}{" "}
        <button
          type="button"
          style={{ float: "right" }}
          className="nes-btn"
          onClick={() => Amplify.Auth.signOut()}
        >
          Sign out
        </button>
        <div style={{ clear: "both" }} />
      </div>
    </>
  );
};

const App = () => {
  return (
    <Login>
      <BrowserRouter>
        <div className="application-main">
          <Route exact path="/" component={List} />
          <Route path="/moderation" component={Moderation} />
          <Header />
        </div>
      </BrowserRouter>
    </Login>
  );
};

export default App;
