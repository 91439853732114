import React from "react";
import { API, graphqlOperation } from "aws-amplify";
import { listQuestions } from "../graphql/queries";
import { updateQuestion } from "../graphql/mutations";
import { onUpdateQuestion } from "../graphql/subscriptions";

const getQuestions = (action) => {
  API.graphql(
    graphqlOperation(listQuestions, {
      limit: 1000,
      filter: { approved: { eq: true } },
    })
  ).then((d) =>
    action(
      d.data.listQuestions.items.map((el) => ({
        ...el,
        timestamp: Date.parse(el.date),
        votes: isNaN(parseInt(el.votes)) ? 0 : parseInt(el.votes),
        voted: localStorage.getItem(el.date + el.username),
      }))
    )
  );
};

const List = () => {
  const [all, setAll] = React.useState([]);
  React.useEffect(() => {
    getQuestions(setAll);
  }, [setAll]);

  React.useEffect(() => {
    const subscription = API.graphql(
      graphqlOperation(onUpdateQuestion)
    ).subscribe({
      next: () => {
        console.log("next");
        getQuestions(setAll);
      },
    });
    return () => subscription.unsubscribe();
  }, [setAll]);

  return (
    <div className="nes-container " style={{ marginBottom: "1rem" }}>
      {all
        .filter((e) => e.answered !== true)
        .sort((a, b) => (a.timestamp > b.timestamp ? 1 : -1))
        .map((el) => (
          <ListItem {...el} />
        ))}
      {all
        .filter((e) => e.answered === true)
        .sort((a, b) => (a.timestamp > b.timestamp ? 1 : -1))
        .map((el) => (
          <ListItem {...el} />
        ))}
    </div>
  );
};

function ListItem({
  id,
  content,
  date,
  username,
  answered,
  approved,
  votes,
  voted,
}) {
  const disabled = voted === "1";

  return (
    <section
      className={`message-left && ${answered && "message-answered"}`}
      style={{ marginBottom: "4rem" }}
    >
      <div
        className="nes-balloon from-left"
        style={{ width: "100%", position: "relative" }}
      >
        <p>{content}</p>
      </div>
      <p>{username}</p>
    </section>
  );
}

export default List;
