import React from "react";
import { API, graphqlOperation } from "aws-amplify";
import { listQuestions } from "../graphql/queries";
import { onCreateQuestion } from "../graphql/subscriptions";
import { updateQuestion, deleteQuestion } from "../graphql/mutations";

const getQuestions = (action) => {
  API.graphql(graphqlOperation(listQuestions, { limit: 1000 })).then((d) =>
    action(
      d.data.listQuestions.items.map((el) => ({
        ...el,
        timestamp: Date.parse(el.date),
      }))
    )
  );
};

const Moderation = () => {
  const [all, setAll] = React.useState([]);
  React.useEffect(() => {
    getQuestions(setAll);
  }, [setAll]);

  React.useEffect(() => {
    const subscription = API.graphql(
      graphqlOperation(onCreateQuestion)
    ).subscribe({
      next: () => {
        getQuestions(setAll);
      },
    });
    return () => subscription.unsubscribe();
  }, [setAll]);
  console.log(all);
  return (
    <div class="nes-container " style={{ marginBottom: "1rem" }}>
      {all
        .sort((a, b) => (a.timestamp > b.timestamp ? -1 : 1))
        .map((el) => (
          <ListItem {...el} />
        ))}
    </div>
  );
};

function ListItem({ id, content, date, username, answered, approved }) {
  const submit = (action) => () => {
    const input = { id };
    switch (action) {
      case "approve":
        input.approved = true;
        input.answered = false;
        break;
      default:
        input.answered = true;
        input.approved = true;
    }
    API.graphql(graphqlOperation(updateQuestion, { input }))
      .then((e) => {
        window.location.reload();
      })
      .catch((e) => console.log(e));
  };

  const remove = () => {
    const input = { id };
    API.graphql(graphqlOperation(deleteQuestion, { input }))
      .then((e) => {
        window.location.reload();
      })
      .catch((e) => console.log(e));
  };

  return (
    <section className={`message-left && ${answered && "message-answered"}`}>
      <div className={`nes-container is-rounded `}>
        <p>{content}</p>
        <p>
          <strong>Asked:</strong> <i>{date}</i>
        </p>

        {!approved && (
          <button
            type="button"
            class="nes-btn is-primary"
            onClick={submit("approve")}
          >
            Approve
          </button>
        )}

        <button
          type="button"
          class="nes-btn is-success"
          onClick={submit("answered")}
        >
          Mark as answered
        </button>

        <button type="button" class="nes-btn is-error" onClick={remove}>
          Remove
        </button>
      </div>
    </section>
  );
}

export default Moderation;
